import React, { useState } from "react";
import { RedocStandalone } from "redoc";
import "./index.scss";

type Entry = {
  label: string;
  path: string;
};

const entries: Entry[] =
  process.env.NODE_ENV !== "development"
    ? [
        {
          label: "Targeting",
          path: "redoc/targeting-module/targeting-module.v1.json",
        },
      ]
    : [
        {
          label: "User management",
          path: "redoc/user-rights-management/user-rights-management.v1.json",
        },
        {
          label: "Targeting",
          path: "redoc/targeting-module/targeting-module.v1.json",
        },
        {
          label: "Campaigns",
          path: "redoc/campaign-repository/campaign-repository.v1.json",
        },
      ];

export const App: React.FC = () => {
  const [specUrl, setSpecUrl] = useState<string>(entries[0].path);

  const showNav = entries.length > 1;
  const redocElement = (
    <div className="redoc-container">
      {specUrl && <RedocStandalone specUrl={specUrl} />}
    </div>
  );

  if (showNav !== true) {
    return redocElement;
  }

  return (
    <div className="container">
      <nav>
        {entries.map((entry, index) => (
          <button
            className={entry.path === specUrl ? "selected" : ""}
            key={index}
            onClick={() => {
              setSpecUrl(entry.path);
            }}
          >
            {entry.label}
          </button>
        ))}
      </nav>
      {redocElement}
    </div>
  );
};
